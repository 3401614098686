import React from "react";
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";

import { Collapse } from "antd";

const { Panel } = Collapse;

function Faq() {
  return (
    <>
      <Navbar />
      <div id="faq" className="block faqBlock">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>Frequently Asked Questions</h2>
          </div>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="What Are CFDs?" key="1">
              <p>
                CFDs are shorter term fixed rate returns on assets. These CFDs
                have only two outcomes for each trade—the asset’s price either
                goes up or down. They are also referred to as Digital CFDs or
                Fixed Return CFDs. To make a trade you simply choose the asset
                you wish to trade, choose your expiry time, and decide whether
                the asset will be a Call (Up) or Put (Down) option.
              </p>
            </Panel>
            <Panel
              header="Do I Have To Install Any Software In Other To Trade?"
              key="2"
            >
              <p>
                You do not need to install any software in order to trade on our
                platform.
              </p>
            </Panel>
            <Panel
              header="What Are Available To Trade On The Platform?"
              key="3"
            >
              <p>
                For a full list of our available assets please go to our Asset
                Index
              </p>
            </Panel>
            <Panel header="Do Yo Offer Demo Accounts?" key="4">
              <p>
                We do not offer demo accounts however we recommend that you
                contact us and one of our knowledgeable account managers will be
                able to help you to set up an account and learn to trade.
              </p>
            </Panel>
            <Panel header="How Do I Open An Account?" key="5">
              <p>
                Opening an account is very easy all you need is to record your
                first name, last name, phone number and e-mail. You can register
                by clicking here "here link to register page" If you encounter
                any problems while opening an account, please contact our
                support team
              </p>
            </Panel>
            <Panel header="Does It Cost Anything To Open An Account?" key="6">
              <p>
                There is no cost to register and open and account with us,
                however in order for you to begin trading, a deposit will be
                mandatory. That may require, entering your credit card/ banking
                information.
              </p>
            </Panel>
            <Panel header="Do I Have To Pay Taxes On My Payout?" key="7">
              <p>
                All traders are responsible for all tax liabilities in their
                area of residence. We highly recommend that you check if tax
                laws in your jurisdiction apply.
              </p>
            </Panel>
            <Panel header="How Secure Is vinextraders?" key="8">
              <p>
                We use the internationally accepted security system SSL (Secure
                Socket Layer) that encrypts all credit card payments over the
                internet. SSL is the encryption system used by large
                corporations such as Bank of America, Google, Amazon, and many
                others.
              </p>
            </Panel>
            <Panel
              header="Do I Have To Send Document To Open An Account?"
              key="9"
            >
              <p>
                You are not required to send in any documentation when you
                register for an account. When you deposit funds into your
                account we do ask that you send us the required documentation in
                order to validate your account for withdrawals. For more
                information on the documents that are required please look at
                the Withdrawal policy and Account verification at the bottom of
                the homepage.
              </p>
            </Panel>
            <Panel header="What Is The Smallest Account Offered?" key="10">
              <p>The smallest account that we offer is for $1,000.</p>
            </Panel>
            <Panel header="Is There A Minimum Withdrawal Account?" key="11">
              <p>
                The minimum withdrawal amount is 50 units in your selected
                currency, unless less than this amount remains in your account.
                In this case, you may make a final withdrawal of the remainder
                of your funds
              </p>
            </Panel>
            <Panel header="What Currency Can I Trade With My Account?" key="12">
              <p>
                You can deposit funds into your account in USD, GBP, EUR or
                Bitcoin. Please note that once you deposit funds into your
                account you cannot change your currency.
              </p>
            </Panel>
          </Collapse>
          <div className="quickSupport">
            <h3>Want quick support?</h3>
            <p>
              Please use our live chat or contact support team @
              vinex@californiamail.com
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Faq;
