import React from 'react';
import Banner from '../../../components/Banner/Banner';
import CoinsTable from '../../../components/Banner/CoinsTable';

const UserHomepage = () => {

  return (
      <>
        <Banner />
        <CoinsTable />
      </>
  );
};

export default UserHomepage;
