import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Avatar, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { DashBoardState } from "../../../DashBoardContext";
import { db } from "../../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 350,
    padding: 25,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "monospace",
    backgroundColor: "#2a2a2b",
  },
  profile: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    height: "92%",
    color: "#EEBC1D",
  },
  logout: {
    height: "8%",
    width: "100%",
    backgroundColor: "#EEBC1D",
    marginTop: 20,
    margin: theme.spacing(1),
  },
  dashboard: {
    height: "8%",
    width: "100%",
    backgroundColor: "#EEBC1D",
    marginTop: 20,
    margin: theme.spacing(1),
  },
  fund: {
    height: "8%",
    width: "100%",
    backgroundColor: "#29b352",
    marginTop: 20,
    margin: theme.spacing(1),
  },
  wallet: {
    height: "8%",
    width: "100%",
    backgroundColor: "#EEBC1D",
    marginTop: 20,
    margin: theme.spacing(1),
  },
  withdraw: {
    height: "8%",
    width: "100%",
    backgroundColor: "#e3274c",
    marginTop: 20,
    margin: theme.spacing(1),
  },
  picture: {
    width: 200,
    height: 200,
    cursor: "pointer",
    backgroundColor: "#EEBC1D",
    objectFit: "contain",
  },
}));

export default function UserSidebar() {
  const classes = useStyles();

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  const { setAlert } = DashBoardState();

  const [error, setError] = useState("");
  const { logout, currentUser } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    setError("");
    setAlert({
      open: true,
      type: "success",
      message: "Logout Successfull !",
    });

    try {
      await logout();
      history.push("/Login");
      setAlert({
        open: true,
        type: "success",
        message: "Logout Successfull !",
      });
    } catch {
      setError("Failed to log out");
    }
  }

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Avatar
            onClick={toggleDrawer(anchor, true)}
            style={{
              height: 42,
              width: 45,
              marginLeft: 15,
              cursor: "pointer",
              backgroundColor: "#EEBC1D",
            }}
          />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {error && <Alert variant="danger">{error}</Alert>}
            <div className={classes.container}>
              <div className={classes.profile}>
                <Avatar className={classes.picture} />
                <span
                  style={{
                    width: "100%",
                    fontSize: 25,
                    textAlign: "center",
                    fontWeight: "bolder",
                    wordWrap: "break-word",
                  }}
                >
                  {" "}
                  {dbState?.firstName}
                </span>

                <span style={{ fontSize: 15, textShadow: "0 0 5px black" }}>
                  PROFILE
                </span>
              </div>

              <Button
                className={classes.dashboard}
                variant="contained"
                href="/UserDashboard"
              >
                DASHBOARD
              </Button>

              <Button
                className={classes.wallet}
                variant="contained"
                href="/Wallet"
              >
                WALLET
              </Button>

              <Button className={classes.fund} variant="contained" href="/Fund">
                FUND WALLET
              </Button>

              <Button
                className={classes.withdraw}
                variant="contained"
                href="/Withdraw"
              >
                WITHDRAW FROM WALLET
              </Button>

              <Button
                variant="contained"
                className={classes.logout}
                onClick={handleLogout}
              >
                Log Out
              </Button>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
