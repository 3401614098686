import React, { useEffect } from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { SectionTitle } from "../../components/sectionTitle";
import Aos from "aos";
import "aos/dist/aos.css";

import AboutImgUrl from "../../assets/illustrations/more.jpg";

const MoreAboutContainer = styled(Element)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1em;
`;

const AboutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    flex-direction: column-reverse;
  }
`;

const AboutText = styled.p`
  font-size: 21px;
  color: #2f2f2f;
  font-weight: normal;
  line-height: 1.4;
`;

const AboutImg = styled.img`
  width: 600px;
  height: 500px;
  margin-left: 2em;

  @media screen and (max-width: 480px) {
    width: 370px;
    height: 290px;
    margin-left: 0;
  }
`;

export function MoreAboutSection(props) {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <MoreAboutContainer>
      <SectionTitle
        data-aos="fade-down"
        data-aos-anchor-placement="center-center"
      >
        More About VineX Traders
      </SectionTitle>
      <AboutContainer>
        <AboutText data-aos="fade-left">
          vinextraders Traders is founded with the vision to create a platform
          that's 100% transparent. Our company is interested in investors who
          sieze opportunities and take risks to invest in crypto currencies
          based on our analysis provided by our expert traders{<br />}
          {<br />} We make sure that you get the your money's worth on each
          investment you make with our company {<br />}
          {<br />} So whether you are new to cryptocurrency trading or you’re
          just starting out, you are in the right place.
        </AboutText>
        <AboutImg src={AboutImgUrl} data-aos="flip-left" />
      </AboutContainer>
    </MoreAboutContainer>
  );
}
