import React from 'react';
import { BrandLogo } from './brandLogo';
import { AppBar, Container, createTheme, Toolbar, ThemeProvider, Select, MenuItem} from "@material-ui/core";
import { DashBoardState } from '../DashBoardContext';
import UserSidebar from '../containers/comp/Authentication/UserSidebar';
const Header = () => {
    const {currency, setCurrency } = DashBoardState()


    const darkTheme = createTheme({
        palette:{
            primary:{
                main: "#fff",
            },
            type: "dark",
        },
    });


  return (
      <ThemeProvider theme={darkTheme}>
      <AppBar color='transparent' position='static'>
          <Container>
                <Toolbar>
                    <BrandLogo />
                        <Select
                         variant='outlined'
                         labelId="demo-simple-select-label"
                         id="demo-simple-select"
                         style={{
                            color: "white",
                            width: 100,
                            height: 40,
                            marginLeft: 15,
                         }} 
                         value={currency}
                         onChange={(e) => setCurrency(e.target.value)}
                         >
                            <MenuItem value={"USD"}>USD</MenuItem>
                            <MenuItem value={"GBP"}>GBP</MenuItem>
                        </Select>

                        {<UserSidebar />}
                </Toolbar>
          </Container>
      </AppBar>
      </ThemeProvider>
  )
};

export default Header;
