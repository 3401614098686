import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import { OurSerivce } from "../../components/ourService";
import { SectionTitle } from "../../components/sectionTitle";

import Service1Img from "../../assets/illustrations/vfsc.png";
import Service2Img from "../../assets/illustrations/multi.jpg";
import Service3Img from "../../assets/illustrations/sec.jpeg";
import Service4Img from "../../assets/illustrations/acc.png";

const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const ServicesContainers = styled(Element)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

export function ServicesSection(props) {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <>
      <div>
        <ServicesContainer name="servicesSection">
          <SectionTitle
            data-aos="fade-up"
            data-aos-anchor-placement="center-center"
          >
            Discover a better trading experience
          </SectionTitle>
          <Marginer direction="vertical" margin="3em" />

          <ServicesContainers data-aos="fade-up-left">
            <OurSerivce
              title="VFSC Regulation"
              description="our company is authorised and regulated bt VFSC government financial commission"
              imgUrl={Service1Img}
              data-aos="fade-left"
            />
          </ServicesContainers>

          <ServicesContainers data-aos="fade-up-right">
            <OurSerivce
              title="Multiplatform"
              description="Our trading platform is available on all devices for a better access and trading experience"
              imgUrl={Service2Img}
              isReversed
              data-aos="fade-right"
            />
          </ServicesContainers>

          <ServicesContainers data-aos="fade-up-left">
            <OurSerivce
              title="Security Standards"
              description="Verified by Visa and Mastercard. All data is encrypted with strongest crptographic algorithms "
              imgUrl={Service3Img}
            />
          </ServicesContainers>

          <ServicesContainers data-aos="fade-up-right">
            <OurSerivce
              title="Accurate quotes"
              description="Real-time market data provided by leading analytical agencies and professionals"
              imgUrl={Service4Img}
              isReversed
            />
          </ServicesContainers>
        </ServicesContainer>
      </div>
    </>
  );
}
