import React, { useState } from "react";
import Header from "../../../components/Header";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  Box,
  FormControl,
  TextField,
  CardContent,
  Button,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import WithdrawalHistory from "../../../components/WithdrawalHistory";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  withdraw: {
    backgroundColor: "#12101c",
    color: "white",
    minHeight: "100vh",
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: "white",
  },
  card: {
    backgroundColor: "#201d36",
    color: "white",
  },
  title: {
    fontSize: 18,
    fontFamily: "Montserrat",
  },
  title1: {
    fontSize: 14,
    paddingTop: theme.spacing(2),
    fontFamily: "Montserrat",
    color: "gold",
  },
  text: {
    marginTop: theme.spacing(2),
    color: "gold",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
  },
  withdrawal: {
    backgroundColor: "#e3274c",
  },
}));

function Withdraw() {
  const classes = useStyles();
  const [newAmount, setNewAmount] = useState("");
  const [withdrawalType, setWithdrawalType] = useState("");
  const [description, setDescription] = useState("");
  const [openAlert, setOpenAlert] = useState(false);

  const isFormValid = newAmount && withdrawalType && description;

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleChangeAmount = (event) => {
    setNewAmount(event.target.value);
  };

  const handleChangeWithdrawalType = (event) => {
    setWithdrawalType(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const createWithdraw = async () => {
    // await addDoc(createwithdrawFundRef, {
    //   withdrawAmount: newAmount,
    //   withdrawalType,
    //   description,
    // });
    setOpenAlert(true);
  };

  return (
    <div className={classes.withdraw}>
      <Header />
      <Container className={classes.container} fixed>
        <Box sx={{ height: "5vh" }} />
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title} gutterBottom>
              Request Withdrawal
            </Typography>
            <form>
              <Typography className={classes.title1} gutterBottom>
                Withdraw Code
              </Typography>
              <TextField
                className={classes.text}
                disabled
                defaultValue="FGRw6DPSTb"
                label="Withdraw Code"
                variant="outlined"
                fullWidth
              />
              <Typography className={classes.title1} gutterBottom>
                Amount
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-amount">
                  Amount
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  type="number"
                  value={newAmount}
                  onChange={handleChangeAmount}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  labelWidth={60}
                />
              </FormControl>
              <Typography className={classes.title1} gutterBottom>
                Withdrawal Type
              </Typography>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Withdrawal Type</InputLabel>
                <Select
                  native
                  value={withdrawalType}
                  onChange={handleChangeWithdrawalType}
                >
                  <option aria-label="None" value="" />
                  <option>Bitcoin</option>
                  <option>Ethereum</option>
                </Select>
              </FormControl>
              <Typography className={classes.title1} gutterBottom>
                Description
              </Typography>
              <TextField
                className={classes.text}
                id="Description"
                label="Enter Description"
                variant="outlined"
                fullWidth
                value={description}
                onChange={handleChangeDescription}
              />
            </form>
          </CardContent>
          <Button
            variant="contained"
            className={classes.withdrawal}
            onClick={createWithdraw}
            disabled={!isFormValid}
            gutterBottom
          >
            Withdraw
          </Button>
        </Card>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="success">
            Withdrawal Processing! updates will appear on your dashboard
            shortly. Any delay in processing, please contact admin with proof of
            withdrawal.
          </Alert>
        </Snackbar>
        <CardContent>
          <Typography className={classes.title} gutterBottom>
            Withdrawal History
          </Typography>
        </CardContent>
        <div className="withdraw">
          <WithdrawalHistory />
        </div>
      </Container>
    </div>
  );
}

export default Withdraw;
