import React, { useEffect, useState } from "react";
import { Navbar } from "../../components/navbar";
import { Footer } from "../../components/footer";
import { useAuth } from "../../contexts/AuthContext";
import {
  Container,
  CssBaseline,
  Avatar,
  Typography,
  Button,
  Grid,
  makeStyles,
  Card,
  CardContent,
} from "@material-ui/core";
import { LockRounded } from "@material-ui/icons";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { ToastContainer, toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { setupUserData } from "./Authentication/userdata";

const Signup = (props) => {
  const classes = useStyles();
  const { signup } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();

  const override = `
        display: block;
        margin-left: 100px;
        border-color: red;
    `;

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await signup(email, password).then(
        () => setupUserData({ firstName, lastName, email, password }),

        toast.success("Registered Successfully")
      );

      history.push("UserDashboard");
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
          toast.error(error.message);
          break;
        case "auth/invalid-email":
          toast.error(error.message);
          break;
        case "auth/weak-password":
          toast.error(error.message);
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== password) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [password]);

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <Card className={classes.card}>
          <CardContent>
            <ToastContainer />
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockRounded />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
              <ValidatorForm onSubmit={handleSignUp} className={classes.form}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="First Name"
                  onChange={handleFirstName}
                  name="First Name"
                  value={firstName}
                  validators={["required"]}
                  errorMessages={["this field is required", "Enter First Name"]}
                  autoComplete="off"
                />
                <br />
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Last Name"
                  onChange={handleLastName}
                  name="Last Name"
                  value={lastName}
                  validators={["required"]}
                  errorMessages={["this field is required", "Enter Last Name"]}
                  autoComplete="off"
                />
                <br />
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Email"
                  onChange={handleEmail}
                  name="email"
                  value={email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "this field is required",
                    "email is not valid",
                  ]}
                  autoComplete="off"
                />
                <br />
                <TextValidator
                  variant="outlined"
                  fullWidth
                  label="Password"
                  onChange={handlePassword}
                  name="password"
                  type="password"
                  value={password}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  autoComplete="off"
                />
                <br />
                <TextValidator
                  variant="outlined"
                  label="Confirm password"
                  fullWidth
                  onChange={handleConfirmPassword}
                  name="confirmPassword"
                  type="password"
                  validators={["isPasswordMatch", "required"]}
                  errorMessages={[
                    "password mismatch",
                    "this field is required",
                  ]}
                  value={confirmPassword}
                  autoComplete="off"
                />

                {loading ? (
                  <ScaleLoader
                    css={override}
                    size={150}
                    color={"#8360c3"}
                    loading={loading}
                  />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                    disabled={loading}
                  >
                    Sign Up
                  </Button>
                )}

                <Grid container>
                  <Grid item>
                    <Link
                      to="/Login"
                      onClick={props.toggle}
                      className={classes.pointer}
                      variant="body2"
                    >
                      {"Already have an account? Sign In"}
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#8360c3",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    background: "linear-gradient(45deg, #2ebf91 30%, #8360c3 90%)",
    margin: theme.spacing(3, 0, 2),
    color: "#fff",
  },
  card: {
    marginTop: "60px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  pointer: {
    cursor: "pointer",
    color: "red",
  },
}));
export default Signup;
