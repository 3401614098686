import React, { useEffect, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";

const List = () => {
  const { currentUser } = useAuth();
  let dbStateRef = useRef();
  const [dbState, setDbState] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();
      setDbState(doc.data());
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const rows = [];
  for (let i = 1; i <= 100; i++) {
    const id = dbState?.[`wid${i}`];
    const date = dbState?.[`wdate${i}`];
    const amount = dbState?.[`wamount${i}`];
    const status = dbState?.[`wstatus${i}`];
    const type = dbState?.[`wtype${i}`];

    rows.push({ id, date, amount, status, type });
  }

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = page * rowsPerPage;

  function getStatusColor(status) {
    switch (status) {
      case "Success":
        return "#87ff5e";
      case "Pending":
        return "#ffe08a";
      case "Failed":
        return "#ff636b";
      default:
        return "initial";
    }
  }

  return (
    <div>
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Withdrawal Reference</TableCell>
              <TableCell className="tableCell">Date</TableCell>
              <TableCell className="tableCell">Amount $</TableCell>
              <TableCell className="tableCell">Type</TableCell>
              <TableCell className="tableCell">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(startIndex, endIndex).map((row, index) => (
              <TableRow key={index}>
                <TableCell className="tableCell">{row?.id}</TableCell>
                <TableCell className="tableCell">{row?.date}</TableCell>
                <TableCell className="tableCell">{row?.amount}</TableCell>
                <TableCell className="tableCell">{row?.type}</TableCell>
                <TableCell className="tableCell">
                  <span
                    className="status"
                    style={{
                      backgroundColor: getStatusColor(row?.status),
                      color: "white",
                      padding: "8px",
                      borderRadius: "4px",
                    }}
                  >
                    {row?.status}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(rows.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        color="primary"
        shape="rounded"
        size="large"
        siblingCount={1}
        boundaryCount={1}
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      />
    </div>
  );
};

export default List;
