const firebaseConfig = {
  apiKey: "AIzaSyARJ9Nmz4W5ss1ziOn7DuLV5HT5_ZBLJy8",
  authDomain: "vinextraders.firebaseapp.com",
  projectId: "vinextraders",
  storageBucket: "vinextraders.appspot.com",
  messagingSenderId: "150972355995",
  appId: "1:150972355995:web:3e356f243327798e557d3e",
  measurementId: "G-S6Q928XZ9S",
};

export default firebaseConfig;
