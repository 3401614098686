import { CircularProgress } from "@material-ui/core"
import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  

  return (
    <Route
      {...rest}
      render={props => {
        return currentUser === undefined?<span><CircularProgress size={350} thickness={2} style={{ color: "gold", alignItems: "center" }}/></span>:currentUser?<Component {...props}/>: <Redirect to="/Login" />
        
      }}
    ></Route>
  )
}