import "./table.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { Paper } from "@material-ui/core";

const List = () => {
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  let bal = dbState?.balance;
  let prof = dbState?.profits;
  let dates = dbState?.date;
  let stat = dbState?.status;
  let cus = dbState?.starts;
  let prod = dbState?.product;
  let tid = dbState?.id;

  const rows = [
    {
      id: tid,
      product: prod,
      img: "https://img.icons8.com/external-smashingstocks-thin-outline-color-smashing-stocks/67/000000/external-forex-finance-smashingstocks-thin-outline-color-smashing-stocks.png",
      customer: cus,
      date: dates,
      amount: bal,
      profits: prof,
      status: stat,
    },
  ];
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">ID</TableCell>
            <TableCell className="tableCell">Product</TableCell>
            <TableCell className="tableCell">Trade Started By</TableCell>
            <TableCell className="tableCell">Date Started</TableCell>
            <TableCell className="tableCell">Amount $</TableCell>
            <TableCell className="tableCell">Profits $</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">{row.id}</TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.customer}</TableCell>
              <TableCell className="tableCell">{row.date}</TableCell>
              <TableCell className="tableCell">{row.amount}</TableCell>
              <TableCell className="tableCell">{row.profits}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
