import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Accessibility } from "./accsessibilty";
import { MenuToggle } from "./menuToggle";

const NavLinksContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  color: #201d36;
`;

const LinksWrapper = styled.ul`
  display: flex;
  height: 100vh;
  list-style: none;
  background-color: #1c2237;
  width: 100%;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s ease;
  z-index: 5;
`;

const LinkItem = styled.li`
  width: 100%;
  padding: 0 1.1em;
  color:   #fff ;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  margin-bottom: 10px;
`;

const Marginer = styled.div`
  height: 2em;
`;

const LinkButton = styled.button`
  border: 0;
  outline: 0;
  padding: 8px 1em;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 600;
  border-radius: 20px;
  background-color: transparent;
  border: 2px solid #EEBC1D;
  transition: all 240ms ease-in-out;
  cursor: pointer;
  &:hover {
    color: #fff;
    background-color: #00c9ff;
  }
  &:not(:last-of-type) {
    margin-right: 7px;
  }
`;

export function MobileNavLinks(props) {
  const [isOpen, setOpen] = useState(false);

  return (
    <NavLinksContainer>
      <MenuToggle isOpen={isOpen} toggle={() => setOpen(!isOpen)} />
      {isOpen && (
        <LinksWrapper>
          <LinkItem>
          <Link to='/'>
          <LinkButton>Home</LinkButton>
          </Link>
          </LinkItem>
          <LinkItem>
          <Link to='/accountTypes'>
          <LinkButton>Account Types</LinkButton>
          </Link>
          </LinkItem>
          <LinkItem>
          <Link to='/Faq'>
          <LinkButton>FAQ</LinkButton>
          </Link>
          <Link to='/UserDashboard'>
          <LinkButton>Dashboard</LinkButton>
          </Link>
          </LinkItem>
          <Marginer />
          <Accessibility />
        </LinksWrapper>
      )}
    </NavLinksContainer>
  );
}