import React from 'react';
import './userDashboard.css';
import Header from '../../components/Header';
import UserHomepage from './pages/UserHomepage';
import { makeStyles } from "@material-ui/core/styles";

function UserDashboard() {
    const useStyles = makeStyles(() => ({
      UserDashboard:{
        backgroundColor: '#14161a',
        color: "white",
        minHeight: "100vh"
      },
    }));

    const classes = useStyles();

    
  return (
    <>
        <div className={classes.UserDashboard}>
          <Header />
          <UserHomepage />
         </div>
      </>
  
  );
}

export default UserDashboard;
