import React from "react";
import { Homepage } from "./containers/homepage";

import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Signup from "./containers/comp/Signup";
import Login from "./containers/comp/Login";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./containers/PrivateRoute";
import accountTypes from "./containers/accountTypes/accountTypes";
import faq from "./containers/faq/faq";
import UserDashboard from "./containers/dashboard/UserDashboard";
import CoinPage from "./containers/dashboard/pages/CoinPage";
import Fund from "./containers/dashboard/pages/Fund";
import Withdraw from "./containers/dashboard/pages/Withdraw";
import Wallet from "./containers/dashboard/pages/Wallet";

function App(props) {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/login" exact component={Login} />
            <Route path="/Signup" exact component={Signup} />
            <Route path="/accountTypes" exact component={accountTypes} />
            <Route path="/faq" exact component={faq} />
            <PrivateRoute
              exact
              path="/UserDashboard"
              component={UserDashboard}
            />
            <PrivateRoute exact path="/coins/:id" component={CoinPage} />
            <PrivateRoute exact path="/Fund" component={Fund} />
            <PrivateRoute exact path="/Wallet" component={Wallet} />
            <PrivateRoute exact path="/Withdraw" component={Withdraw} />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
