import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { BrandLogo } from "../brandLogo";
import { Link } from "react-router-dom";
import { Marginer } from "../marginer";

import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faEye } from "@fortawesome/free-solid-svg-icons";
import { deviceSize } from "../responsive";
import { useMediaQuery } from "react-responsive";
import FooterSectionBackgroundImg from "../../images/footer.webp";

const FooterContainer = styled.div`
  width: 100%;
  min-height: 200px;
  background: url(${FooterSectionBackgroundImg}) no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2em 3em;
  padding-bottom: 0;
  border-top: 0.6px solid rgb(0, 0, 0, 0.3);
  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 2em 12px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1em;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;

  &:not(:last-of-type) {
    margin-right: 3%;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  border-top: 0.6px solid rgb(0, 0, 0, 0.3);
  padding: 0 10px;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;
  }
`;

const RightBottomContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;
`;

const LeftBottomContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;
`;

const AnchorLink = styled(Link)`
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: all 200ms ease-in-out;

  &:hover {
    color: #0d2d19;
  }

  @media screen and (max-width: ${deviceSize.mobile}px) {
    padding: 0;
  }
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 13px;
  color: #485412;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    color: #fff;

`;

const PrivacyText = styled.h6`
  color: #fff;
  font-size: 11px;
  margin: 0;
  margin-left: 10px;
  display: flex;
  margin-top: 5px;
  align-items: center;

  &:hover {
    color: #485412;
  }

  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 8px;
  }
`;

const SloganText = styled.h3`
  margin: 0;
  line-height: 1.5;
  color: #b8b7b6;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  padding-top: 1em;

  &:hover {
    color: #485412;
  }

  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 11px;
    font-weight: bold;
  }
`;

const SocialIcon = styled.div`
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: background-color, 200ms ease-in-out;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  &:hover {
    color: #485412;
  }
  @media screen and (max-width: ${deviceSize.mobile}px) {
    font-size: 17px;
  }
`;

export function Footer(props) {
  const isMobile = useMediaQuery({ maxWidth: deviceSize.mobile });

  return (
    <FooterContainer>
      <BrandLogo logoSize={isMobile ? 33 : 40} textSize={isMobile ? 28 : 35} />
      <TopContainer>
        <SloganText>
          Trading and investing in digital options involves significant level of
          risk and is not suitable for all clients. Please make sure you
          carefully consider your investment objectives, level of experience and
          risk appetite before buying or selling any digital option. Buying or
          selling digital options entails financial risks and could result in a
          partial or complete loss of your funds, therefore, you should not
          invest funds you cannot afford to lose. You should be aware of and
          fully understand all the risks associated with trading and investing
          in digital options, and seek advice from an independent financial
          advisor if you have any doubts.
        </SloganText>
        <Marginer direction="vertical" margin={8} />

        <ContentContainer>
          <Title> Contact Us</Title>
          <SocialIcon>
            <FontAwesomeIcon icon={faEnvelope} />
            <PrivacyText>vinex@californiamail.com</PrivacyText>
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faEye} />
            <PrivacyText>Door open</PrivacyText>
            <PrivacyText>Mon - Sun : 09:00 - 18:00</PrivacyText>
          </SocialIcon>
        </ContentContainer>

        <ContentContainer>
          <Title>Quick Links</Title>
          <AnchorLink to="/">
            <PrivacyText>Home</PrivacyText>
          </AnchorLink>
          <AnchorLink to="/Login">
            <PrivacyText>Login</PrivacyText>
          </AnchorLink>
          <AnchorLink to="/Signup">
            <PrivacyText>Join Us</PrivacyText>
          </AnchorLink>
          <AnchorLink to="/faq">
            <PrivacyText>FAQ</PrivacyText>
          </AnchorLink>
          <AnchorLink to="/accountTypes">
            <PrivacyText>AccountTypes</PrivacyText>
          </AnchorLink>
        </ContentContainer>
      </TopContainer>
      <BottomContainer>
        <LeftBottomContainer>
          <BrandLogo hideLogo color="#F3F3F3" textSize={isMobile ? 20 : 25} />
          <PrivacyText>&#169; All Rights Reserved. 2022</PrivacyText>
        </LeftBottomContainer>
        <RightBottomContainer>
          <SocialIcon>
            <FontAwesomeIcon icon={faFacebook} />
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faTwitter} />
          </SocialIcon>
          <SocialIcon>
            <FontAwesomeIcon icon={faInstagram} />
          </SocialIcon>
        </RightBottomContainer>
      </BottomContainer>
    </FooterContainer>
  );
}
