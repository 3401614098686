import React from "react";
import "./wallet.scss";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../components/Header";
import BitcoinWidget from "../../../components/Widget";
import EthereumWidget from "../../../components/EthWidget";
import ProfitsWidget from "../../../components/ProfitWidget";
import BalanceWidget from "../../../components/BalanceWidget";
import TotalBalanceWidget from "../../../components/TotalBalanceWidget";
import Table from "../../../components/Table";
import Button from "@material-ui/core/Button";

const Wallet = () => {
  const useStyles = makeStyles((theme) => ({
    Wallet: {
      backgroundColor: "#12101c",
      width: "100%",
      color: "white",
      minHeight: "200vh",
    },
    fund: {
      height: "8%",
      backgroundColor: "#29b352",
      marginTop: 20,
      marginLeft: 25,
      margin: theme.spacing(1),
    },
    withdraw: {
      height: "8%",
      backgroundColor: "#e3274c",
      marginTop: 20,
      margin: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  return (
    <div className="wallet">
      <div className="walletContainer">
        <div className={classes.Wallet}>
          <Header />
          <div className="widgets">
            <BitcoinWidget type="bitcoin" />
            <EthereumWidget type="ethereum" />
            <ProfitsWidget type="profits" />
            <BalanceWidget type="balance" />
            <TotalBalanceWidget type="totalBalance" />
          </div>
          <div className="listContainer">
            <div className="listTitle">Latest Trades</div>
            <Table />
          </div>

          <Button className={classes.fund} variant="contained" href="/Fund">
            FUND WALLET
          </Button>

          <Button
            className={classes.withdraw}
            variant="contained"
            href="/Withdraw"
          >
            WITHDRAW FROM WALLET
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
