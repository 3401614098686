import "./totalBalancewidget.scss";
import React, { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const Widget = ({ type }) => {
  const { currentUser } = useAuth();

  let data;

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  let tbal = dbState?.totalBalance;

  data = {
    title: "BALANCE",
    isBalance: true,

    icon: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/30/000000/external-wallet-black-friday-flaticons-lineal-color-flat-icons.png"
        className="icon"
        style={{
          color: "crimson",
          backgroundColor: "rgba(255, 0, 0, 0.2)",
        }}
      />
    ),
  };

  return (
    <>
      <div className="widget">
        <div className="left">
          <span className="title">{data.title}</span>
          <span className="counter">
            {data.isBalance && "$"} {tbal}
          </span>
          <span className="link">{data.link}</span>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpIcon />%
          </div>
          {data.icon}
        </div>
      </div>
    </>
  );
};

export default Widget;
