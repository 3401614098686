import React, { useEffect, useState } from "react";
import { makeStyles, Container, Typography } from "@material-ui/core";
import Carousel from "./Carousel";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles(() => ({
  bannerContent: {
    height: 400,
    display: "flex",
    flexDirection: "column",
    paddingTop: 25,
    justifyContent: "space-around",
  },
  tagline: {
    display: "flex",
    height: "40%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
}));

function Banner() {
  const classes = useStyles();
  const { currentUser } = useAuth();

  let dbStateRef = React.useRef();

  const [dbState, setDbState] = useState(dbStateRef.current);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
      dbStateRef.current = doc.data();

      setDbState(dbStateRef.current);
    });

    return () => unsub;
    // eslint-disable-next-line jsx-a11y/alt-text, react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.Banner}>
      <Container className={classes.bannerContent}>
        <div className={classes.tagline}>
          <Typography
            variant="h2"
            style={{
              fontWeight: "bold",
              color: "white",
              marginBottom: 15,
              fontFamily: "Montserrat",
            }}
          >
            Welcome
          </Typography>
          <span
            style={{
              fontSize: 25,
              fontWeight: "bolder",
              fontFamily: "Cabin",
            }}
          >
            {dbState?.firstName}
          </span>
          <Typography
            variant="subtitle2"
            style={{
              color: "gold",
              textTransform: "capitalize",
              fontFamily: "Montserrat",
            }}
          >
            Your trading account has been created and is active
          </Typography>
        </div>
        <Carousel />
      </Container>
    </div>
  );
}

export default Banner;
